









































































































import DatePicker from '@/components/ui/DatePicker.vue'
import { formatarMoeda } from '@/shareds/formatadores'
import { FormaDePagamento, PontoDeVendaComTurno, Titulo } from '@/models'
import { BaixaDePagamentoForm } from '@/models/financeiro/BaixaDePagamento'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { CreateBaixaDePagamentoUseCase } from '@/usecases'
import { Vue, Component, Watch, Ref, Prop } from 'vue-property-decorator'
import moment from 'moment-timezone'
import { imprimirComprovanteDeRecebivel } from '@/shareds/venda-shareds'
import { FindPdvUseCase } from '@/usecases/pdv/FindPdvUseCase'

@Component({
	components: {
		DatePicker,
	},
})
export default class DialogoDeEdicaoDeBaixasDePagamento extends Vue {
	@Ref() form!: HTMLFormElement
	@Prop({ type: Boolean, default: false }) retornoCrediario!: boolean
	@Prop({ type: Boolean, default: false }) contasAPagar!: boolean


	buscando = false
	salvando = false
	mostra = false
	menu2 = false
	time = null
	titulo: Titulo | null = null
	valorFaltante: number | null = null
	formatarMoeda = formatarMoeda
	formaDePagamento = ''
	turnoDeVendaId: string | null = null

	dataPagamento: string | null = null

	createBaixaDePagamentoUseCase = new CreateBaixaDePagamentoUseCase()
	findPdvUseCase = new FindPdvUseCase()

	pdvOptions: PontoDeVendaComTurno[] = []

	baixaDePagamento: BaixaDePagamentoForm = {
		titulo: null,
		transactionId: '',
		valorPagamento: 0,
		valorTaxa: 0,
		dataHoraPagamento: '',
		isTaxa: false,
		isIntegracao: false,
		formaDePagamento: null,
		valorDesconto: 0,
		turnoDeVendaId: null,
	}

	opcoesDeFormaDePagamento: FormaDePagamento[] = [
		'Dinheiro',
		'Cartão',
		'Pix',
	]

	opcoesDeFormaDePagamentoCaixa: FormaDePagamento[] = [
		'Dinheiro',
		'Cartão',
		'Pix',
	]

	mostrarDialogo(titulo: Titulo | null, valorFaltante: number) {
		this.pdvOptions = []
		this.mostra = true;
		this.titulo = titulo ? titulo : null;
		this.valorFaltante = valorFaltante;

		this.preencherPdvAutomatico();
		this.buscarPdvAberto();
	}

	@Watch('baixaDePagamento.valorPagamento')
	validarDesconto(desconto) {
		
		const valorFaltante = this.valorFaltante;

		if (valorFaltante === null) {
			return 'Valor faltante não definido';
		}

		const descontoNumerico = Number(desconto);
		const valorPagamentoNumerico = Number(this.baixaDePagamento.valorPagamento);
		const valorTaxaNumerico = Number(this.baixaDePagamento.valorTaxa);

		const soma = descontoNumerico + valorPagamentoNumerico + valorTaxaNumerico;
		
		if (soma > valorFaltante) {
			return 'A soma do desconto, pagamento e taxa não pode ser maior que o valor faltante';
		}
		return true;
	}
	
	preencherPdvAutomatico() {
		if (this.contasAPagar) return;
		const turnoDeVenda = JSON.parse(localStorage.getItem('TURNO_DE_VENDA_STORAGE') || 'null');

		if (turnoDeVenda && turnoDeVenda.pontoDeVenda && this.titulo && this.titulo.loja) {

			if (turnoDeVenda.pontoDeVenda.loja.id === this.titulo.loja.id) {
				const pdv = {
					turnoDeVendaId: turnoDeVenda.id,
					nomePontoDeVenda: turnoDeVenda.pontoDeVenda.nome,
				};

				if (!this.pdvOptions.some(p => p.turnoDeVendaId === pdv.turnoDeVendaId)) {
					this.pdvOptions = [pdv, ...this.pdvOptions];
				}

				if (!this.baixaDePagamento.turnoDeVendaId) {
					this.baixaDePagamento.turnoDeVendaId = pdv.turnoDeVendaId;
				}
			}
		}
	}



	async buscarPdvAberto() {
		if (!this.titulo || !this.titulo.loja) return;
		const pdvs = await this.findPdvUseCase.buscarPdvSemTurno(this.titulo.loja.id);
		this.pdvOptions = [...this.pdvOptions, ...pdvs];
	}

	async salvarBaixa() {
		if (!this.baixaDePagamento) return
		if (!this.titulo) return

		if (this.valorFaltante === null) {
			return AlertModule.setError('Valor faltante não pode ser nulo!')
		}

		if ((!this.baixaDePagamento.valorPagamento && !this.baixaDePagamento.valorDesconto) 
			|| (this.baixaDePagamento.valorPagamento <= 0 && this.baixaDePagamento.valorDesconto <=0)
		) {
			return AlertModule.setError('Valor de pagamento ou desconto é obrigatório!')
		}
		
		if (Number(this.baixaDePagamento.valorPagamento) + Number(this.baixaDePagamento.valorDesconto) + Number(this.baixaDePagamento.valorTaxa) > Number(this.valorFaltante)) {
			return AlertModule.setError('A soma do desconto, pagamento e taxa não pode ser maior que o valor faltante')
		}

		const valorPagamento = Number(this.baixaDePagamento.valorPagamento);
		const valorTaxa = Number(this.baixaDePagamento.valorTaxa);
		const totalPagamento = Math.round((valorPagamento + valorTaxa) * 100) / 100;
		const valorFaltanteArredondado = Math.round(this.valorFaltante * 100) / 100;

		if (totalPagamento > valorFaltanteArredondado) {
			return AlertModule.setError('Valor de pagamento acrescido da taxa não pode ser maior que o valor')
		}
		if ( this.baixaDePagamento.valorTaxa > this.valorFaltante) {
			return AlertModule.setError('Valor de taxa não pode ser maior que o valor faltante!')
		}
		if (!this.formaDePagamento) {
			return AlertModule.setError('Forma de pagamento é obrigatória!')
		}
		if (!this.baixaDePagamento.turnoDeVendaId && !this.contasAPagar) {
			return AlertModule.setError('Necessário Ponto De Venda')
		}
		
		try {
			this.salvando = true
			this.baixaDePagamento.titulo = this.titulo

			this.baixaDePagamento.formaDePagamento = this.formaDePagamento

			const comprovanteDaBaixa = await this.createBaixaDePagamentoUseCase.create(this.baixaDePagamento)
			await imprimirComprovanteDeRecebivel(comprovanteDaBaixa)
			this.$emit('atualizar', this.baixaDePagamento)
			this.limparDados()
	
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
			this.mostra = false
		}
	}

	async limparDados() {
		this.baixaDePagamento = {
			titulo: null,
			transactionId: '',
			valorPagamento: 0,
			valorTaxa: 0,
			dataHoraPagamento: '',
			isTaxa: false,
			isIntegracao: false,
			formaDePagamento: null,
			valorDesconto: 0,
			turnoDeVendaId: null,
		}
		this.time = null
		this.dataPagamento = null
		this.mostra = false
	}

	cancelar() {
		this.limparDados()
	}

	gerarDataHora(data?: string | null, hora?: string | null): string {
		const dateTimeString = `${data}T${hora}:00`;
		const localDateTime = moment(dateTimeString, 'YYYY-MM-DDTHH:mm:ss');
		const utcDateTime = localDateTime.utcOffset(0, true); // Define o offset para 0 (UTC)
		const utcDateTimeString = utcDateTime.toISOString();

		return utcDateTimeString;
	}



	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if (!mostra) return
		window.requestAnimationFrame(() => {
			this.form.resetValidation()
		})
	}
}
